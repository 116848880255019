import { Button } from '@shared/design-system/atoms/button';
import { useState } from 'react';

import NutritionLabelDialog from '~/layouts/_shared/dialogs/nutriton-label-dialog';

const Nutrition = () => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(true);
  };

  return (
    <>
      <NutritionLabelDialog isOpened={isOpened} setIsOpened={setIsOpened} />
      <Button
        className='bg-white hover:bg-white focus:bg-white active:bg-white opacity-100 md:hover:opacity-70 border border-neutral-0 text-neutral-0 transition-opacity'
        variant='primary'
        size='md'
        onClick={handleClick}
      >
        View Nutrition Label
      </Button>
    </>
  );
};

export default Nutrition;
