import { forwardRef } from 'react';

import { Content } from '~/components/content';

import Breadcrumbs from './breadcrumbs';
import ProductDetails from './product-details';
import ProductSelection from './product-selection';
import Reputation from './reputation';

const Purchase = forwardRef<HTMLDivElement>((_props, ref) => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] bg-white py-10 md:py-12'>
      <div ref={ref} id='purchase' className='flex flex-col gap-6 md:gap-4'>
        <div className='flex flex-col gap-6 md:gap-4 md:pt-10 md:pb-16'>
          <h2 className='font-tertiary font-bold text-3xl md:text-5xl text-center leading-none'>
            Get Your
            <br />
            Harmonia Drink
          </h2>
          <Breadcrumbs />
          <Reputation />
        </div>
        <div className='flex md:flex-row flex-col gap-20 md:gap-4'>
          <ProductDetails />
          <ProductSelection />
        </div>
      </div>
    </Content>
  );
});

Purchase.displayName = 'Purchase';

export default Purchase;
