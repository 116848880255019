import { Button } from '@shared/design-system/atoms/button';
import Image from 'next/image';
import Link from 'next/link';
import { RefObject } from 'react';

import CheckSVG from '~/assets/icons/check.svg';
import HeroProductBackgroundImage from '~/assets/images/landing/hero-product-background.png';
import HeroProductFullImage from '~/assets/images/landing/hero-product-full.png';
import HeroProductTransparentImage from '~/assets/images/landing/hero-product-transparent.png';
import { Content } from '~/components/content';
import { useAppRouter } from '~/hooks/useAppRouter';
import { scrollToRef } from '~/utils/scroll-to-ref';

type HeroProps = {
  purchaseContainerRef: RefObject<HTMLDivElement>;
  title?: string;
  subTitle?: string;
  benefitItems?: {
    id: number;
    text: string;
  }[];
};

const styleVars: React.CSSProperties = {
  ['--bg-image' as string]: `url(${HeroProductBackgroundImage.src})`,
};

const Hero = ({ purchaseContainerRef, title, subTitle, benefitItems }: HeroProps) => {
  const { getNextFunnelRounte, isPathPlans } = useAppRouter('/quiz');

  const handleBuyNowClick = () => {
    scrollToRef(purchaseContainerRef);
  };

  return (
    <Content gridClassName='col-[edge-left_/_edge-right]' viewClassName='col-[edge-left_/_edge-right]'>
      <div className='flex md:flex-row flex-col md:bg-[image:var(--bg-image)] bg-cover' style={styleVars}>
        <div className='flex-1'>
          <Image
            src={HeroProductFullImage}
            width={748}
            height={593}
            alt='Product sample'
            className='md:hidden w-full h-full md:object-contain'
          />
          <Image
            src={HeroProductTransparentImage}
            width={748}
            height={593}
            alt='Product sample'
            className='hidden md:block w-full md:max-h-[] md:object-contain'
          />
        </div>
        <div className='flex flex-col flex-1 justify-center'>
          <div className='flex flex-col gap-6 md:p-14 px-6 pb-6'>
            <div className='flex flex-col gap-4 md:gap-8'>
              <div className='flex flex-col items-center md:items-start gap-1 md:gap-2'>
                {title ? (
                  <h1 className='font-tertiary font-bold text-4xl md:text-7xl md:text-left text-center leading-none'>
                    {title}
                  </h1>
                ) : null}

                {subTitle ? (
                  <p className='font-tertiary text-2xl md:text-3xl md:text-left text-center leading-none'>{subTitle}</p>
                ) : null}
              </div>
              {benefitItems ? (
                <ul className='flex flex-col items-center md:items-start gap-1.5'>
                  {benefitItems.map(({ id, text }) => (
                    <li key={id} className='flex items-center gap-2'>
                      <div className='shrink-0'>
                        <CheckSVG />
                      </div>
                      <p className='md:text-md text-sm'>{text}</p>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
            <div className='flex items-center gap-3'>
              {isPathPlans ? null : (
                <Link className='flex-1 w-full md:max-w-[10.875rem]' href={getNextFunnelRounte()}>
                  <Button variant='secondary' size='md' className='w-full'>
                    Take the quiz
                  </Button>
                </Link>
              )}

              <Button
                variant='primary'
                size='md'
                className='flex-1 w-full md:max-w-[10.875rem]'
                onClick={handleBuyNowClick}
              >
                Buy now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Hero;
