import { Product } from '@menesko/models-harmonia';
import { useEffect, useMemo, useState } from 'react';

import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';
import { useTracking } from '~/hooks/useTracking';
import { AppRoute } from '~/types';
import { filterProductsByIds } from '~/utils/product';

import ProductListing from './product-listing';
import ProductTaste from './product-taste';

const ProductSelection = () => {
  const { products } = useProducts();
  const { updateLead } = useAppLead();
  const { plansSelect } = useTracking();
  const { pushNextFunnelRoute } = useAppRouter(AppRoute.CHECKOUT);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();

  const subscriptionProducts = useMemo(() => filterProductsByIds(products, ['34', '33', '32']), [products]);
  const oneTimeProducts = useMemo(() => filterProductsByIds(products, ['35']), [products]);

  const handleSelect = (product: Product) => {
    setSelectedProduct(product);
  };

  const handleSubmit = () => {
    if (!selectedProduct) {
      return;
    }

    setIsLoading(true);

    updateLead({ selectedPlan: selectedProduct });

    plansSelect({
      category: selectedProduct.key,
      label: selectedProduct.title,
    });

    setTimeout(() => {
      // TODO: use pushNextFunnelRoute intead for funnels tracking
      pushNextFunnelRoute();
    }, 1000);
  };

  useEffect(() => {
    if (subscriptionProducts.length > 0) {
      setSelectedProduct(subscriptionProducts[0]);
    } else if (oneTimeProducts.length > 0) {
      setSelectedProduct(oneTimeProducts[0]);
    }
  }, [subscriptionProducts, oneTimeProducts]);

  return (
    <div className='flex-1'>
      <div className='flex flex-col gap-8 md:gap-10 md:px-4 md:py-6'>
        <div className='flex flex-col self-center gap-2 md:max-w-[358px]'>
          <h3 className='font-tertiary font-bold text-3xl text-center leading-none'>Choose Your Offer</h3>
          <p className='font-tertiary text-2xl text-center leading-none'>
            Autoship & Save. Best for achieving sustainable results.
          </p>
        </div>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-4 md:gap-6'>
            {subscriptionProducts.length > 0 ? (
              <ProductListing
                products={subscriptionProducts}
                selectedProduct={selectedProduct}
                isLoading={isLoading}
                onSelect={handleSelect}
                onSubmit={handleSubmit}
              />
            ) : null}
            {oneTimeProducts.length > 0 ? (
              <ProductListing
                products={oneTimeProducts}
                selectedProduct={selectedProduct}
                isLoading={isLoading}
                onSelect={handleSelect}
                onSubmit={handleSubmit}
              />
            ) : null}
          </div>
          <ProductTaste />
        </div>
      </div>
    </div>
  );
};

export default ProductSelection;
