import { forwardRef } from 'react';

import { Content } from '~/components/content';
import { CortisolCocktailToggle } from '~/components/index';

import Breadcrumbs from '../purchase/breadcrumbs';
import ProductDetails from '../purchase/product-details';
import ProductTaste from '../purchase/product-selection/product-taste';
import Reputation from '../purchase/reputation';

const PurchaseOld = forwardRef<HTMLDivElement>((_props, ref) => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] bg-white py-10 md:py-12'>
      <div ref={ref} id='purchase' className='flex flex-col gap-6 md:gap-4'>
        <div className='flex flex-col gap-6 md:gap-4 md:pt-10 md:pb-16'>
          <h2 className='font-tertiary font-bold text-3xl md:text-5xl text-center leading-none'>
            Get Your
            <br />
            Harmonia Drink
          </h2>
          <Breadcrumbs breadcrumbs={['0 calories', 'Delicious', 'Vegan']} />
          <Reputation />
        </div>
        <div className='flex md:flex-row flex-col gap-20 md:gap-8'>
          <div className='md:w-1/2'>
            <ProductDetails />
          </div>
          <div className='md:w-1/2'>
            <h3 className='md:hidden mb-6 font-tertiary font-bold text-4xl text-center'>Choose your offer</h3>
            <CortisolCocktailToggle singleColumn />
            <ProductTaste className='mt-6' />
          </div>
        </div>
      </div>
    </Content>
  );
});

PurchaseOld.displayName = 'PurchaseOld';

export default PurchaseOld;
