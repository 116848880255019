import {
  Accordion as AccordionBase,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
} from '@shared/design-system/atoms/accordion';
import React from 'react';

import ArrowDownSvg from '~/assets/icons/arrow-down.svg';
import { useAppRouter } from '~/hooks/useAppRouter';

const Accordion = () => {
  const { funnelName } = useAppRouter();
  const isRoot = funnelName === 'main';

  return (
    <AccordionBase type='multiple'>
      <AccordionItem value='1' className='group py-3 border-orange-100 first:border-t border-b'>
        <AccordionHeader>
          <AccordionTrigger className='flex justify-between items-center gap-4 w-full text-left'>
            <span className='font-tertiary font-bold text-xl'>Benefits</span>
            <div className='group-data-[state=open]:rotate-180'>
              <ArrowDownSvg />
            </div>
          </AccordionTrigger>
        </AccordionHeader>

        <AccordionContent>
          <div className='flex flex-col gap-4 pt-4'>
            <ul className='marker:!m-0 pl-6 list-disc list-outside'>
              <li className='text-sm [&>span]:font-semibold'>Lowers cortisol levels</li>
              {!isRoot && <li className='text-sm [&>span]:font-semibold'>Helps with weight management</li>}
              <li className='text-sm [&>span]:font-semibold'>Relieves mood swings</li>
              <li className='text-sm [&>span]:font-semibold'>Promotes better sleep</li>
              <li className='text-sm [&>span]:font-semibold'>Helps with PCOS</li>
              <li className='text-sm [&>span]:font-semibold'>Improves menstrual cycle</li>
              <li className='text-sm [&>span]:font-semibold'>Fights abdominal fat area</li>
              <li className='text-sm [&>span]:font-semibold'>Enhances overall well-being</li>
            </ul>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value='2' className='group py-3 border-orange-100 first:border-t border-b'>
        <AccordionHeader>
          <AccordionTrigger className='flex justify-between items-center gap-4 w-full text-left'>
            <span className='font-tertiary font-bold text-xl'>Why Harmonia</span>
            <div className='group-data-[state=open]:rotate-180'>
              <ArrowDownSvg />
            </div>
          </AccordionTrigger>
        </AccordionHeader>

        <AccordionContent>
          <div className='flex flex-col gap-4 pt-4'>
            <p className='text-sm'>It&apos;s simple!</p>
            <ul className='marker:!m-0 pl-6 list-disc list-outside'>
              <li className='text-sm [&>span]:font-semibold'>
                <span>Comprehensive:</span> Ingredients that work. Harmonia supports hormones like nothing else in a way
                that makes you feel great!
              </li>
              <li className='text-sm [&>span]:font-semibold'>
                <span>Delicious:</span> A morning ritual that will get you hooked
              </li>
            </ul>
            <p className='text-sm'>No surprise that 9/10 people recommend it to others!</p>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value='3' className='group py-3 border-orange-100 first:border-t border-b'>
        <AccordionHeader>
          <AccordionTrigger className='flex justify-between items-center gap-4 w-full text-left'>
            <span className='font-tertiary font-bold text-xl'>Ingredients & Allergies</span>
            <div className='group-data-[state=open]:rotate-180'>
              <ArrowDownSvg />
            </div>
          </AccordionTrigger>
        </AccordionHeader>

        <AccordionContent>
          <div className='flex flex-col gap-4 pt-4'>
            <div className='flex flex-col'>
              <p className='font-bold text-sm'>Ingredients</p>
              <p className='text-sm'>Harmonia contains over 16 vitamins & minerals:</p>
            </div>

            <ul className='marker:!m-0 pl-6 list-disc list-outside'>
              <li className='text-sm [&>span]:font-semibold'>
                <span>Vitamins:</span> Vitamin D (as cholecalciferol) Vitamin C (as ascorbic acid) Vitamin B6 (as
                pyridoxine HCI) Folate (as calciu L-5-methyltetrahydrofolate) Vitamin B12 (as cyanocobalamin) Chromium
                (as chromium picolinate) Magnesium (as magnesium bisglycinate) Potassium (as potassium chloride)
              </li>
              <li className='text-sm [&>span]:font-semibold'>
                <span>Proprietary Cortisol Blend:</span> Inositol, Phosphatidylserine (from soybean), L-theanine,
                Ashwagandha root & leaf extract (Shoden), Tumeric root, Rhodiola Rosea root, Caronositol D-chiro
                inositol, Black peper fruit extract
              </li>
            </ul>
            <p className='font-bold text-sm'>Allergies</p>
            <ul className='marker:!m-0 pl-6 list-disc list-outside'>
              <li className='text-sm [&>span]:font-semibold'>
                Harmonia is plant-based and vegan. Harmonia is free from gluten, dairy, eggs, peanuts, corn, herbicides
                or pesticides, GMO&apos;s, dextrose.
              </li>
              <li className='text-sm [&>span]:font-semibold'>
                Harmonia contains soy, that comes from one ingredient - Phosphatidylserine.
              </li>
              <li className='text-sm [&>span]:font-semibold'>
                Harmonia has 0 calories so it is okay to drink when fasting, being on keto or doing any other diet.
              </li>
              <li className='text-sm [&>span]:font-semibold'>
                Other ingredients and sweeteners: Citric acid, natural flavours, silica, beta carotene (color),
                sucralose and monk fruit extract.
              </li>
            </ul>
          </div>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value='4' className='group py-3 border-orange-100 first:border-t border-b'>
        <AccordionHeader>
          <AccordionTrigger className='flex justify-between items-center gap-4 w-full text-left'>
            <span className='font-tertiary font-bold text-xl'>Directions</span>
            <div className='group-data-[state=open]:rotate-180'>
              <ArrowDownSvg />
            </div>
          </AccordionTrigger>
        </AccordionHeader>

        <AccordionContent>
          <div className='flex flex-col gap-4 pt-4'>
            <ul className='marker:!m-0 pl-6 list-disc list-outside'>
              <li className='text-sm [&>span]:font-semibold'>
                Take 1 scoop (5.8g) daily in 8-12 ounces (240-350 ml) of cold water or your liquid of choice. Stir/shake
                and enjoy. If the taste is too intense - add more water.
              </li>
              <li className='text-sm [&>span]:font-semibold'>
                For cortisol/stress reduction{isRoot ? '' : ', weight-loss'}, metabolism, adrenal support and PCOS best
                to consume in the morning - with breakfast. For better sleep and relaxation consume in the evening
                (30-60 min before bed)
              </li>
              <li className='text-sm [&>span]:font-semibold'>1 bag lasts for 30 days</li>
            </ul>
          </div>
        </AccordionContent>
      </AccordionItem>
    </AccordionBase>
  );
};

export default Accordion;
