import React from 'react';

import BreadcrumbsDividerSvg from '~/assets/icons/breadcrumbs-divider.svg';

const defaultBreadcrumbs = ['0 calories', 'Delicious', 'Vegan', 'Promotes Weight-loss'];

const Breadcrumbs = ({ breadcrumbs = defaultBreadcrumbs }: { breadcrumbs?: string[] }) => {
  return (
    <div className='flex flex-wrap justify-center items-center gap-1.5'>
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb}>
          <p className='font-tertiary text-xl leading-none'>{breadcrumb}</p>
          {index < breadcrumbs.length - 1 ? <BreadcrumbsDividerSvg /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
