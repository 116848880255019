import { Product, ProductType } from '@menesko/models-harmonia';
import { cn } from '@shared/design-system/utils/shadcn';

type ProductCardProps = {
  product: Product;
  selected?: boolean;
  onSelect: (product: Product) => void;
};

export const ProductCard = ({ product, selected, onSelect }: ProductCardProps) => {
  const prices = product.prices || [];
  const quantity = product.quantity || 1;
  const price = prices.find((price) => price.currency === 'USD');

  const pricePerBag = (price?.price || 0) / quantity;
  const priceFinalPerBag = (price?.finalPrice || 0) / quantity;
  const discountPercentage = Math.round(
    price?.price && price?.finalPrice ? ((price.price - price.finalPrice) / price.price) * 100 : 0,
  );
  const pricePerDay = Math.floor((priceFinalPerBag / 30) * 100) / 100;

  const name = product.productType === ProductType.SUBSCRIPTION ? `${quantity}-month supply` : 'Try once';

  return (
    <button
      onClick={() => onSelect(product)}
      className={cn(
        'group block w-full text-left p-4 rounded-xl',
        selected &&
          'bg-white shadow-[0px_0px_0px_1px_rgba(0,0,0,0.05),0px_1px_3px_0px_rgba(0,0,0,0.10),0px_2px_12px_0px_rgba(0,0,0,0.05)]',
      )}
    >
      <div className='flex items-start gap-3'>
        <div
          className={cn(
            'bg-white border border-neutral-0/5 rounded-full size-6',
            selected && 'border-green-600 border-[6px]',
          )}
        />
        <div className='flex flex-col flex-1 gap-2'>
          <div className='flex justify-between items-center gap-2'>
            <p className='font-medium'>{name}</p>
            {product.productType === ProductType.SUBSCRIPTION ? (
              <div className={cn('bg-orange-200 px-2 py-0.5 rounded-full', selected && 'bg-green-600')}>
                <p className={cn('text-black text-sm', selected && 'text-white')}>{discountPercentage}% OFF</p>
              </div>
            ) : null}
          </div>
          <div className='flex flex-col gap-1'>
            <div className='flex items-center gap-1'>
              <p className='font-tertiary font-bold text-2xl leading-none'>${priceFinalPerBag.toFixed(2)}</p>
              <p className='text-sm'>
                per bag <span className='text-black/50 line-through'>${pricePerBag.toFixed(2)}</span>
              </p>
            </div>
            <p className='text-black/50 text-xs'>${pricePerDay} Per Day</p>
          </div>
        </div>
      </div>
    </button>
  );
};
