import Image from 'next/image';

import TasteLeafsImage from '~/assets/images/landing/taste-leafs.png';
import TasteOrangeImage from '~/assets/images/landing/taste-orange.png';
import TastePlus from '~/assets/images/landing/taste-plus.png';

const ProductTaste = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <h2 className='mb-8 font-tertiary font-bold text-3xl md:text-5xl text-center'>Taste like</h2>
      <div className='relative'>
        <div className='flex justify-center items-center'>
          <div className='flex flex-col flex-1 items-center gap-2 max-w-[8.5rem]'>
            <Image src={TasteLeafsImage} width={136} height={89} alt='Leafs' />
            <p className='font-tertiary font-bold text-xl text-center'>Fresh & light</p>
          </div>

          <div className='flex flex-col flex-1 items-center gap-2 max-w-[8.5rem]'>
            <Image src={TasteOrangeImage} width={90} height={90} alt='Onrage' />
            <p className='font-tertiary font-bold text-xl text-center'>Orange</p>
          </div>
        </div>
        <Image src={TastePlus} width={20} height={20} alt='Plus' className='top-5 left-1/2 absolute -translate-x-1/2' />
      </div>
    </div>
  );
};

export default ProductTaste;
