import Image, { StaticImageData } from 'next/image';

import LowerStressMainOneDesktopImage from '~/assets/images/landing/lower-stress-main-1.png';

import { GridItem } from './grid-item';

export type PrimaryGridProps = {
  headline: string;
  items: {
    id: number;
    image: StaticImageData;
    title: string;
    paragraph: string;
  }[];
};

const PrimaryGrid = ({ headline, items }: PrimaryGridProps) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-[var(--grid-gap)]'>
      <h2 className='order-2 md:order-1 md:col-span-full pt-4 md:pt-10 pb-4 md:pb-6 font-tertiary font-bold text-3xl md:text-5xl text-center leading-none'>
        {headline}
      </h2>

      <div className='order-1 md:order-2 rounded-xl aspect-[343/225] md:aspect-auto overflow-hidden'>
        <Image
          src={LowerStressMainOneDesktopImage}
          width={576}
          height={641}
          alt='Complementary'
          className='w-full h-full object-center object-cover'
        />
      </div>

      <div className='gap-2 md:gap-4 order-3 grid grid-cols-2'>
        {items.map(({ id, ...item }) => (
          <GridItem key={id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default PrimaryGrid;
