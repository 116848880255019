import { cn } from '@shared/design-system/utils/shadcn';
import { ReactNode } from 'react';

type CardProps = {
  children: ReactNode;
  className?: string;
};

export const Card = ({ children, className }: CardProps) => {
  return (
    <div className={cn('rounded-2xl border border-black/5 p-4 md:py-6 md:px-8 bg-white', className)}>
      <div className='flex flex-col gap-2'>{children}</div>
    </div>
  );
};
