import { Button } from '@shared/design-system/atoms/button';
import Image, { StaticImageData } from 'next/image';
import { RefObject } from 'react';

import { scrollToRef } from '~/utils/scroll-to-ref';

export type SecondaryGridProps = {
  purchaseContainerRef: RefObject<HTMLDivElement>;
  title: string;
  subTitle: string;
  items: {
    id: number;
    image: StaticImageData;
    title: string;
    subTitle: string;
  }[];
  ctaLabel?: string;
};

const SecondaryGrid = ({ title, subTitle, items, ctaLabel, purchaseContainerRef }: SecondaryGridProps) => {
  const handleBuyNowClick = () => {
    scrollToRef(purchaseContainerRef);
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-[var(--grid-gap)]'>
      <div className='col-span-full bg-white px-6 md:px-14 pt-12 md:pt-12 pb-8 md:pb-8 border border-neutral-0/5 rounded-xl'>
        <div className='flex flex-col justify-between items-center gap-8 md:gap-12'>
          <div className='flex flex-col items-center gap-2'>
            <h4 className='font-tertiary font-bold text-3xl md:text-5xl md:text-center leading-none whitespace-pre-line'>
              {title}
            </h4>
            <p className='font-tertiary text-2xl md:text-center leading-none'>{subTitle}</p>
          </div>
          <div className='gap-8 grid grid-cols-2'>
            {items.map(({ id, image, title, subTitle }) => (
              <div key={id} className='flex flex-col gap-2'>
                <Image src={image} width={50} height={50} alt='Pie chart' />
                <p className='font-tertiary font-bold text-6xl leading-none'>{title}</p>
                <p className='text-xs md:text-sm'>{subTitle}</p>
              </div>
            ))}
          </div>
          {ctaLabel ? (
            <Button variant='secondary' size='md' className='md:hidden w-full' onClick={handleBuyNowClick}>
              {ctaLabel}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SecondaryGrid;
