import CheckSvg from '~/assets/icons/check.svg';

const features = ['Ships within 24 Hours Mon-Fri', 'Free avg 3-day shipping today', '30-Day Money Back Guarantee'];

const Shipping = () => {
  return (
    <ul className='flex flex-col gap-1'>
      {features.map((feature) => (
        <li className='flex items-center gap-2'>
          <div className='shrink-0'>
            <CheckSvg />
          </div>
          <p className='text-sm'>{feature}</p>
        </li>
      ))}
    </ul>
  );
};

export default Shipping;
