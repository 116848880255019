import React from 'react';

import { Content } from '~/components/content';

import PrimaryGrid, { PrimaryGridProps } from './primary-grid';
import SecondaryGrid, { SecondaryGridProps } from './secondary-grid';
import TertiaryGrid, { TertiaryGridProps } from './tertiary-grid';

type HormonesProps = {
  primaryGridProps: PrimaryGridProps;
  secondaryGridProps: SecondaryGridProps;
  tertiaryGridProps: TertiaryGridProps;
};

const Hormones = ({ primaryGridProps, secondaryGridProps, tertiaryGridProps }: HormonesProps) => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] py-4 bg-orange-50 md:pb-4 md:pt-12'>
      <div className='grid grid-cols-1 gap-[var(--grid-gap)] [--grid-gap:1rem]'>
        <PrimaryGrid {...primaryGridProps} />
        <SecondaryGrid {...secondaryGridProps} />
        <TertiaryGrid {...tertiaryGridProps} />
      </div>
    </Content>
  );
};

export default Hormones;
