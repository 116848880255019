import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
} from '@shared/design-system/atoms/accordion';
import { Button } from '@shared/design-system/atoms/button';
import Image from 'next/image';
import { RefObject } from 'react';

import CircleOrangeArrowDownSvg from '~/assets/icons/circle-orange-arrow-down.svg';
import GlassWithFallingProductimage from '~/assets/images/landing/glass-with-falling-product.png';
import { scrollToRef } from '~/utils/scroll-to-ref';

export type TertiaryGridProps = {
  purchaseContainerRef: RefObject<HTMLDivElement>;
  preTitle: string;
  title: string;
  timeline: {
    title: string;
    items: {
      id: number;
      title: string;
      content: string;
    }[];
    ctaLabel?: string;
  };
};

const TertiaryGrid = ({ purchaseContainerRef, preTitle, title, timeline }: TertiaryGridProps) => {
  const handleBuyNowClick = () => {
    scrollToRef(purchaseContainerRef);
  };

  return (
    <div className='md:items-start grid grid-cols-1 md:grid-cols-2 md:gap-[var(--grid-gap)]'>
      <div className='relative border border-neutral-0/5 md:rounded-xl rounded-t-xl overflow-hidden'>
        <Image
          src={GlassWithFallingProductimage}
          width={576}
          height={767}
          alt='Glass with falling product'
          className='w-full max-h-[368px] md:max-h-full object-cover'
        />
        <div className='absolute inset-0 flex flex-col gap-0.5 p-6 md:p-12'>
          <p className='md:font-medium text-sm md:text-base'>{preTitle}</p>
          <p className='font-bold font-tertiary text-3xl md:text-5xl leading-none whitespace-pre-line'>{title}</p>
        </div>
      </div>

      <Accordion
        type='single'
        collapsible
        defaultValue='1'
        className='bg-white border border-neutral-0/5 border-t-0 md:rounded-xl rounded-b-xl'
      >
        <AccordionItem value='1'>
          <AccordionHeader className='px-6 md:px-14 py-5'>
            <AccordionTrigger className='group'>
              <div className='flex items-center gap-3'>
                <h5 className='font-tertiary font-bold text-5xl md:text-8xl'>8</h5>
                <p className='md:font-tertiary text-xs md:text-2xl text-left'>{timeline.title}</p>
                <div className='group-data-[state=open]:rotate-180 shrink-0'>
                  <CircleOrangeArrowDownSvg />
                </div>
              </div>
            </AccordionTrigger>
          </AccordionHeader>

          <AccordionContent>
            <div className='flex flex-col gap-7 px-6 md:px-14 pb-8'>
              <div className='flex flex-col'>
                {timeline.items.map(({ id, title, content }) => (
                  <div key={id} className='group flex gap-4'>
                    <div className='flex flex-col items-center gap-0.5'>
                      <div className='group-first:bg-transparent bg-orange-500 w-px h-5 shrink-0' />
                      <div className='bg-orange-500 rounded-full w-2.5 h-2.5 shrink-0' />
                      <div className='group-last:bg-transparent bg-orange-500 w-px h-full' />
                    </div>
                    <div className='flex flex-col pt-4'>
                      <h3 className='relative font-semibold text-gray-900 text-lg'>{title}</h3>
                      <p className='text-gray-600'>{content}</p>
                    </div>
                  </div>
                ))}
              </div>
              {timeline.ctaLabel ? (
                <Button variant='secondary' size='md' onClick={handleBuyNowClick}>
                  {timeline.ctaLabel}
                </Button>
              ) : null}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default TertiaryGrid;
