import Accordion from './accordion';
import Carousel from './carousel';
import Nutrition from './nutrition';
import Shipping from './shipping';

const ProductDetails = () => {
  return (
    <div className='flex flex-col flex-1 gap-6'>
      <Carousel />
      <div className='flex flex-col gap-6 md:p-6'>
        <Nutrition />
        <Shipping />
        <Accordion />
      </div>
    </div>
  );
};

export default ProductDetails;
