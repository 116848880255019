import FiveStarsRatingSvg from '~/assets/icons/five-stars-rating-orange.svg';

const Reputation = () => {
  return (
    <div className='flex justify-center items-center w-full'>
      <div className='flex justify-center items-center gap-2 px-3 py-1.5 border border-orange-100 rounded-full'>
        <p className='font-tertiary font-bold text-xl leading-none'>4.8 stars</p>
        <FiveStarsRatingSvg />
        <p className='text-sm'>from 1245 reviews</p>
      </div>
    </div>
  );
};

export default Reputation;
