import { useRef } from 'react';

import CharPieOneImage from '~/assets/images/landing/chart-pie-1.png';
import CharPieTwoImage from '~/assets/images/landing/chart-pie-2.png';
import CharPieThreeImage from '~/assets/images/landing/chart-pie-3.png';
import CharPieFourImage from '~/assets/images/landing/chart-pie-4.png';
import ChartPieBigTwoImage from '~/assets/images/landing/chart-pie-big-37.png';
import ChartPieBigOneImage from '~/assets/images/landing/chart-pie-big-72.png';
import LowerWeightIllustrationOne from '~/assets/images/landing/lower-weight-illustration-1.png';
import LowerWeightIllustrationTwo from '~/assets/images/landing/lower-weight-illustration-2.png';
import LowerWeightIllustrationThree from '~/assets/images/landing/lower-weight-illustration-3.png';
import LowerWeightIllustrationFour from '~/assets/images/landing/lower-weight-illustration-4.png';
import ReviewOneImage from '~/assets/images/landing/review-1.png';
import ReviewTwoImage from '~/assets/images/landing/review-2.png';
import ReviewThreeImage from '~/assets/images/landing/review-3.png';
import ReviewFourImage from '~/assets/images/landing/review-4.jpeg';
import ReviewFiveImage from '~/assets/images/landing/review-5.png';
import ReviewSixImage from '~/assets/images/landing/review-6.png';
import { Grid } from '~/components/grid';
import { useAppRouter } from '~/hooks/useAppRouter';
import { Funnel } from '~/types';

import Banner from '../_shared/banner';
import Footer from '../_shared/footer';
import Header from '../_shared/header';

import Comparison from './_components/comparison';
import Faq from './_components/faq';
import Hero from './_components/hero';
import Hormones from './_components/hormones';
import Purchase from './_components/purchase';
import PurchaseOld from './_components/purchase-old';
import Researched from './_components/researched';
import Reviews from './_components/reviews';
import Science from './_components/science';
import Testimonials from './_components/testimonials';

export const Landing = () => {
  const purchaseContainerRef = useRef<HTMLDivElement>(null);
  const { funnelName } = useAppRouter();
  const funnelsWithOldPurchaseBlock: Funnel[] = ['main', 'cortisol-3', 'cortisol-5'];
  const isOldPurchaseBlock = funnelsWithOldPurchaseBlock.includes(funnelName);

  return (
    <Grid className='[--max-content:1168px]'>
      <Banner />
      <Header />
      <Hero
        purchaseContainerRef={purchaseContainerRef}
        title='High Cortisol?'
        subTitle='Harmonia will help you to:'
        benefitItems={[
          { id: 1, text: 'Lose weight and get rid of cortisol belly' },
          { id: 2, text: 'Lower your stress levels and anxiety' },
          { id: 3, text: 'Relieve mood swings and improve sleep quality' },
        ]}
      />
      <Researched />
      <Hormones
        primaryGridProps={{
          headline: 'Lower Your Stress Hormones',
          items: [
            {
              id: 1,
              image: LowerWeightIllustrationOne,
              title: 'Promotes weight management',
              paragraph:
                'Supports progesterone and estrogen levels to assist weight management and curb carb cravings.',
            },
            {
              id: 2,
              image: LowerWeightIllustrationTwo,
              title: 'Supports Lower Cortisol & Relaxation',
              paragraph:
                'Contains plant extracts that highly efficient to help reduce stress, ease tension, and promote a calm mind.',
            },
            {
              id: 3,
              image: LowerWeightIllustrationThree,
              title: 'Improves sleep and overall well-being',
              paragraph: 'Improved formula shown to help with relaxation and restless sleep',
            },
            {
              id: 4,
              image: LowerWeightIllustrationFour,
              title: 'Addresses hormonal imbalances',
              paragraph: 'Helps women that have PCOS menopause or thyroid issues.',
            },
          ],
        }}
        secondaryGridProps={{
          purchaseContainerRef,
          title: `Modern Living\nMesses Up Our Stress Hormone`,
          subTitle: ' Harmonia Cocktail is the first smart cocktail that fights exactly that.',
          items: [
            {
              id: 1,
              image: ChartPieBigOneImage,
              title: '72%',
              subTitle:
                'of women struggle to lose weight due to increased cortisol levels, with fat around the abdominal area being their primary concern',
            },
            {
              id: 2,
              image: ChartPieBigTwoImage,
              title: '37%',
              subTitle:
                'of women with sleep issues have higher cortisol which results in hormonal imbalances that leads again to weight gain',
            },
          ],
          ctaLabel: 'Harmonia can solve it',
        }}
        tertiaryGridProps={{
          purchaseContainerRef,
          preTitle: 'Key ingredients',
          title: `Harmonia Cortisol\nBlend`,
          timeline: {
            title: 'powerful strains that boosts healthy cortisol drop & weight loss',
            items: [
              {
                id: 1,
                title: 'L-Theanine',
                content:
                  'helps calm the brain by increasing levels of neurotransmitters like GABA, v serotonin, and dopamine',
              },
              {
                id: 2,
                title: 'Ashwagandha',
                content:
                  'Reduces the stress that often leads to emotional eating and weight gain, particularly in the abdominal area.',
              },
              {
                id: 3,
                title: 'Myo Inositol',
                content:
                  'Balances hormone levels, reduces cravings and stabilizes energy levels, which facilitate healthier eating and exercise habits.',
              },
              {
                id: 4,
                title: 'D-Chiro Inositol',
                content:
                  'Improves insulin function, which can help reduce insulin resistance often linked to conditions like PCOS',
              },
              {
                id: 5,
                title: 'Rhodiola Rosea',
                content: 'Enhances stress resilience with Rhodiola helps prevent fatigue and emotional eating',
              },
              {
                id: 6,
                title: 'Black pepper extract',
                content:
                  'Stimulates the breakdown of fat cells, supporting more efficient metabolism and potentially aiding in weight loss efforts.',
              },
              {
                id: 7,
                title: 'Tumeric',
                content:
                  'Enhances insulin sensitivity and regulates lipid metabolism to aid weight management and prevents obesity-related disorders.',
              },
              {
                id: 9,
                title: 'Phosphatidylserine',
                content: 'Support brain health and cognitive function, can improve focus and decision-making',
              },
              {
                id: 10,
                title: 'Other vitamins',
                content:
                  'Vitamin C, Vitamin D, Vitamin B6, Folate, Vitamin B12, Magnesium, Chronium and Potassium. All of which works in a support to boost the effectivness of the Harmonia drink.',
              },
            ],
            ctaLabel: 'Buy now',
          },
        }}
      />
      <Science
        title={`The Science\nSupporting Harmonia`}
        subTitleParts={[{ label: 'based on ' }, { label: 'clinical studies.', withHighlight: true }]}
        items={[
          { id: 1, image: CharPieOneImage, title: '91%', content: 'Experienced weight loss within 21 days.' },
          {
            id: 2,
            image: CharPieTwoImage,
            title: '68%',
            content: 'Hormonal balance in relation to PCOS and menstrual cycles',
          },
          {
            id: 3,
            image: CharPieThreeImage,
            title: '74%',
            content: 'Optimized nutrient intake that contributed to overall well-being',
          },
          { id: 4, image: CharPieFourImage, title: '95%', content: 'Energy levels increase along with restful sleep' },
        ]}
      />
      {isOldPurchaseBlock ? <PurchaseOld ref={purchaseContainerRef} /> : <Purchase ref={purchaseContainerRef} />}
      <Testimonials
        title={`Thousands of\nHappy Clients`}
        subTitle='This could be you - check out these reviews from happy customers around the works'
        testimonials={[
          {
            id: 1,
            title: 'Remarkable results for unwanted pounds and stress.',
            description:
              "I've been drinking the cortisol cocktail for three months, and the results have been nothing short of remarkable. My stress levels are much lower, and I've lost 17 lbs without making major changes to my diet. The drink is delicious and easy to incorporate into my daily routine. I'm feeling more relaxed and healthier than ever. Highly recommend giving it a try!",
            name: 'Laura U.',
            rating: 5,
            image: ReviewOneImage,
          },
          {
            id: 2,
            title: 'Transformative for my stress and cortisol belly!',
            description:
              "The Harmonia drink has been transformative for me. Over the past two months, I've lost 16 lbs and feel significantly less stressed. The taste is wonderful, and it's become a pleasant part of my daily routine. It's amazing how a simple drink can make such a big difference in both my mental and physical health. I highly recommend it to anyone looking to improve their well-being!",
            name: 'Jessica E.',
            rating: 5,
            image: ReviewTwoImage,
          },
          {
            id: 3,
            title: 'A miracle drink for stress and weight',
            description:
              "The cortisol cocktail drink is amazing! I've been using it for a month, and it's helped me manage my stress levels significantly. On top of that, I've seen a noticeable improvement in my weight loss efforts. The natural ingredients make me feel good about what I'm putting into my body. It's refreshing, effective, and supports both my mental and physical health. Highly recommend it to anyone!",
            name: 'Sandra',
            rating: 5,
            image: ReviewThreeImage,
          },
          {
            id: 4,
            title: 'Energy, calm and 19 lbs down!',
            description:
              "The Harmonia drink has become my go-to beverage for managing stress and boosting energy. As someone who juggles a demanding job and family life, I've found this drink incredibly helpful. It's refreshing, convenient, and really works to keep me calm and focused. Can't imagine my day without it now!",
            name: 'Anna T.',
            rating: 5,
            image: ReviewFourImage,
          },
          {
            id: 5,
            title: 'A refreshing boost for my daily routine!',
            description:
              "I've been searching for a natural way to manage stress, and the cortisol cocktail drink has been a game-changer! As a busy working mom, I needed something easy and effective. This drink not only tastes great but also gives me a noticeable boost in energy and mood. I've felt more balanced and less frazzled throughout the day. Ant most importantly I lost 30 pounds already! Highly recommend!",
            name: 'Tina R.',
            rating: 5,
            image: ReviewFiveImage,
          },
          {
            id: 6,
            title: 'Life-changing drink for stress and health!',
            description:
              "I've been using the Harmonia drink for two months now, and it has truly been life-changing. Not only has it significantly reduced my stress levels, but I've also lost 18 lbs! The taste is great, and it fits seamlessly into my daily routine. I'm feeling more energized, balanced, and healthier overall. I can't recommend this drink enough!",
            name: 'Nichole E.',
            rating: 5,
            image: ReviewSixImage,
          },
        ]}
      />
      <Reviews
        reviews={[
          {
            id: 1,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-1.mp4',
            content:
              'After six weeks, this natural cocktail eased my stress, helped me lose 20 lbs, and tasted amazing!',
          },
          {
            id: 2,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-2.mp4',
            content: 'I felt lighter and calmer—plus the flavor was a delightful bonus.',
          },
          {
            id: 3,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-3.mp4',
            content:
              'This drink transformed my routine in 4 weeks: less stress, noticeable weight loss, and a refreshingly tasty blend.',
          },
          {
            id: 4,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-4.mp4',
            content:
              'With this cocktail brought down my stress and shed pounds—all while enjoying a smooth, natural flavor.',
          },
          {
            id: 5,
            videoUrl: 'https://assets.drinkharmonia.com/videos/testimonial-5.mp4',
            content:
              'I’m thrilled: 5 weeks of this drink led to reduced stress, weight loss, and a delicious, energizing taste.',
          },
        ]}
      />
      <Comparison
        title={`Harmonia\nCortisol Cocktail`}
        subTitle={`Compared To Other,\n Cortisol Lowering Drinks`}
        tableItems={[
          '"Intelligent" cortisol support system',
          'Ingredients adapt to unique needs of your body',
          'Releases stress, improves mood and helps with metabolism',
          'Helps address symptoms of Menopause and PCOS',
          'Plant-based formula',
          "Free from GMO's, corn and gluten. Vegan friendly.",
          'Amazing orange taste',
          'Amazing taste with 0 sugar',
          'Puts your cortisol levels into place',
          'Made in the USA',
        ]}
      />
      <Faq />
      <Footer />
    </Grid>
  );
};
