import { Product } from '@menesko/models-harmonia';

import { ProductCard } from '../product-card';
import ProductShipping from '../product-shipping';

type ProductListing = {
  products: Product[];
  selectedProduct?: Product;
  isLoading: boolean;
  onSelect: (product: Product) => void;
  onSubmit: () => void;
};

const ProductListing = ({ products, selectedProduct, isLoading, onSelect, onSubmit }: ProductListing) => {
  const active = Boolean(products.find((product) => product.id === selectedProduct?.id));

  return (
    <div className='flex flex-col bg-orange-50 p-1 border border-neutral-0/5 rounded-2xl'>
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          selected={product.id === selectedProduct?.id}
          onSelect={onSelect}
        />
      ))}
      {active && selectedProduct ? (
        <ProductShipping product={selectedProduct} onSubmit={onSubmit} isLoading={isLoading} />
      ) : null}
    </div>
  );
};

export default ProductListing;
