import Image from 'next/image';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import BusinessInsiderImage from '~/assets/images/landing/business-insider.png';
import WomensHealthImage from '~/assets/images/landing/womens-health.png';
import { Content } from '~/components/content';

const slides = [
  { id: 1, image: BusinessInsiderImage, width: 103, height: 32 },
  { id: 2, image: WomensHealthImage, width: 115, height: 23 },
  { id: 3, image: BusinessInsiderImage, width: 103, height: 32 },
  { id: 4, image: WomensHealthImage, width: 115, height: 23 },
  { id: 5, image: BusinessInsiderImage, width: 103, height: 32 },
];

const swiperProps: SwiperProps = {
  spaceBetween: 24,
  slidesPerView: 2,
  pagination: {
    type: 'bullets',
    el: '#swiperResearchedPagination',
    clickable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 5,
      pagination: false,
    },
  },
  modules: [Pagination],
};

const Researched = () => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] bg-gradient-to-b from-white to-orange-50 py-6'>
      <div className='flex flex-col'>
        <h2 className='md:font-tertiary text-sm md:text-2xl text-center'>
          Benefits of our ingredients are researched by:
        </h2>

        <div className='md:mx-auto py-6 md:py-12 md:max-w-[59.6875rem]'>
          <Swiper {...swiperProps}>
            {slides.map(({ id, image, width, height }) => (
              <SwiperSlide key={id} className='!h-auto'>
                <div className='flex justify-center items-center h-full'>
                  <Image src={image} width={width} height={height} alt={`Slider researched by logo ${id}`} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          id='swiperResearchedPagination'
          className='flex justify-center items-center gap-2 [&_.swiper-pagination-bullet.swiper-pagination-bullet-active]:bg-black [&_.swiper-pagination-bullet]:bg-black/10 [&_.swiper-pagination-bullet]:rounded-full [&_.swiper-pagination-bullet]:w-2 [&_.swiper-pagination-bullet]:h-2'
        ></div>
      </div>
    </Content>
  );
};

export default Researched;
