import Image, { StaticImageData } from 'next/image';

type GridItemProps = {
  image: StaticImageData;
  title: string;
  paragraph: string;
};

export const GridItem = ({ image, title, paragraph }: GridItemProps) => {
  return (
    <div className='bg-white px-3 md:px-7 py-4 md:py-6 border border-neutral-0/5 rounded-xl'>
      <div className='flex flex-col items-center gap-3'>
        <Image src={image} width={155} height={124} alt={title} />
        <h4 className='font-tertiary font-bold text-xl text-center leading-none'>{title}</h4>
        <p className='opacity-80 text-xs md:text-sm text-center leading-tight'>{paragraph}</p>
      </div>
    </div>
  );
};
