import Image, { StaticImageData } from 'next/image';
import React from 'react';

import TextHighlighterSvg from '~/assets/icons/text-highlighter.svg';
import QualityBadgeImage from '~/assets/images/landing/quality-badge.png';
import ScienceCardBgDesktopImage from '~/assets/images/landing/science-card-bg-desktop.png';
import ScienceCardBgDesktopMobile from '~/assets/images/landing/science-card-bg-mobile.png';
import { Content } from '~/components/content';

import { Card } from './card';

type ScienceProps = {
  title: string;
  subTitleParts: {
    label: string;
    withHighlight?: boolean;
  }[];
  items: {
    id: number;
    image: StaticImageData;
    title: string;
    content: string;
  }[];
};

const Science = ({ title, subTitleParts, items }: ScienceProps) => {
  return (
    <Content gridClassName='col-[edge-left_/_edge-right] py-10 md:py-12 bg-orange-50'>
      <div className='flex flex-col gap-10 md:gap-8'>
        <div className='flex flex-col items-center gap-2 md:py-10'>
          <h2 className='font-tertiary font-bold text-3xl md:text-5xl text-center leading-none whitespace-pre'>
            {title}
          </h2>
          <p className='font-tertiary text-2xl text-center leading-none'>
            {subTitleParts.map(({ label, withHighlight }, index) => (
              <React.Fragment key={index}>
                {withHighlight ? (
                  <span className='relative'>
                    {label}
                    <span className='-bottom-1.5 absolute inset-x-0'>
                      <TextHighlighterSvg />
                    </span>
                  </span>
                ) : (
                  label
                )}
              </React.Fragment>
            ))}
          </p>
        </div>

        <div className='gap-2 md:gap-4 grid grid-cols-2 md:grid-cols-4'>
          {items.map((item) => (
            <Card key={item.id}>
              <Image src={item.image} width={24} height={24} alt='Chart' />
              <p className='font-tertiary font-bold text-3xl md:text-5xl leading-none'>{item.title}</p>
              <p className='text-xs md:text-sm'>{item.content}</p>
            </Card>
          ))}
          <Card className='relative col-span-full md:min-h-[11.6875rem]'>
            <Image src={QualityBadgeImage} width={24} height={33} alt='Quality badge' />
            <p className='font-tertiary font-bold text-xl md:text-3xl leading-none'>Quality You Can Trust</p>
            <p className='max-w-[12.5rem] md:max-w-[22.375rem] text-xs md:text-sm'>
              Harmonia drink is made from highest quality ingredients to ensure label claims are accurate and effective
            </p>
            <Image
              src={ScienceCardBgDesktopImage}
              width={1168}
              height={187}
              alt='Quality badge'
              className='hidden md:block absolute inset-0 w-full h-full object-cover'
            />
            <Image
              src={ScienceCardBgDesktopMobile}
              width={367}
              height={314}
              alt='Quality badge'
              className='md:hidden -top-8 -right-5 absolute'
            />
          </Card>
        </div>
      </div>
    </Content>
  );
};

export default Science;
