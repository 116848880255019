import { Product, ProductType } from '@menesko/models-harmonia';
import { Button } from '@shared/design-system/atoms/button';

import CheckSVG from '~/assets/icons/check.svg';

type ProductShippingProps = {
  product: Product;
  onSubmit: () => void;
  isLoading: boolean;
};

const ProductShipping = ({ product, onSubmit, isLoading }: ProductShippingProps) => {
  const bags = Number(product.quantity);
  const days = product.quantity * 30;
  const portions = product.quantity * 30;
  const isSubscription = product.productType === ProductType.SUBSCRIPTION;
  const bagsLabel = bags === 1 ? 'bag' : 'bags';
  const everyOrFor = isSubscription ? 'every' : 'for';

  return (
    <div className='flex flex-col gap-4 p-4'>
      <ul className='flex flex-col gap-1'>
        <li className='flex items-center gap-2'>
          <CheckSVG />
          <p className='text-sm'>
            {bags} {bagsLabel} {everyOrFor} {days} days ({portions} portions)
          </p>
        </li>
        <li className='flex items-center gap-2'>
          <CheckSVG />
          <p className='text-sm'>Free avg 3-day shipping today</p>
        </li>
        {isSubscription ? (
          <>
            <li className='flex items-center gap-2'>
              <CheckSVG />
              <p className='text-sm'>Pause or cancel anytime</p>
            </li>
            <li className='flex items-center gap-2'>
              <CheckSVG />
              <p className='text-sm'>30-day money back guarantee</p>
            </li>
          </>
        ) : null}
      </ul>
      <Button variant='primary' size='md' onClick={onSubmit} isLoading={isLoading}>
        <span className='size-6'></span>
        <span className='flex-1'>{isSubscription ? 'Start Now' : 'Try Now'}</span>
        <span className='flex justify-center items-center size-6'>
          <Arrow />
        </span>
      </Button>
    </div>
  );
};

const Arrow = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='9' height='14' viewBox='0 0 9 14' fill='none'>
    <path d='M1 13L7 7L1 1' stroke='white' stroke-width='1.5' />
  </svg>
);

export default ProductShipping;
