import { cn } from '@shared/design-system/utils/shadcn';
import Image, { StaticImageData } from 'next/image';
import { useState } from 'react';

import ProductDetailsSlideOneImage from '~/assets/images/landing/product-details-slide-1.png';
import ProductDetailsSlideTwoImage from '~/assets/images/landing/product-details-slide-2.png';
import ProductDetailsSlideThreeImage from '~/assets/images/landing/product-details-slide-3.png';
import ProductDetailsSlideFourImageRoot from '~/assets/images/landing/product-details-slide-4-root.png';
import ProductDetailsSlideFourImage from '~/assets/images/landing/product-details-slide-4.png';
import ProductDetailsSlideFiveImageRoot from '~/assets/images/landing/product-details-slide-5-root.png';
import ProductDetailsSlideFiveImage from '~/assets/images/landing/product-details-slide-5.png';
import ProductDetailsSlideSixImage from '~/assets/images/landing/product-details-slide-6.png';
import ProductDetailsSlideSevenImageRoot from '~/assets/images/landing/product-details-slide-7-root.png';
import ProductDetailsSlideSevenImage from '~/assets/images/landing/product-details-slide-7.png';
import { useAppRouter } from '~/hooks/useAppRouter';

type CarouselItem = {
  id: number;
  image: StaticImageData;
};

const carouselItemsRoot: CarouselItem[] = [
  { id: 1, image: ProductDetailsSlideOneImage },
  { id: 2, image: ProductDetailsSlideTwoImage },
  { id: 3, image: ProductDetailsSlideThreeImage },
  { id: 4, image: ProductDetailsSlideFourImageRoot },
  { id: 5, image: ProductDetailsSlideFiveImageRoot },
  { id: 6, image: ProductDetailsSlideSixImage },
  { id: 7, image: ProductDetailsSlideSevenImageRoot },
];

const carouselItems: CarouselItem[] = [
  { id: 1, image: ProductDetailsSlideOneImage },
  { id: 2, image: ProductDetailsSlideTwoImage },
  { id: 3, image: ProductDetailsSlideThreeImage },
  { id: 4, image: ProductDetailsSlideFourImage },
  { id: 5, image: ProductDetailsSlideFiveImage },
  { id: 6, image: ProductDetailsSlideSixImage },
  { id: 7, image: ProductDetailsSlideSevenImage },
];

const Carousel = () => {
  const { funnelName } = useAppRouter();

  const items = funnelName === 'main' ? carouselItemsRoot : carouselItems;
  const [visibleCarouselItem, setVisibleCarouselItem] = useState(carouselItems[0]);

  const handleClick = (item: CarouselItem) => {
    setVisibleCarouselItem(item);
  };

  return (
    <div className='flex flex-col gap-2'>
      <div className='bg-orange-50 border border-neutral-0/5 rounded-2xl overflow-hidden'>
        <Image src={visibleCarouselItem.image} width={592} height={560} alt='Active' className='w-full h-full' />
      </div>
      <div className='flex gap-2'>
        {items.map((carouselItem) => (
          <button
            key={carouselItem.id}
            className={cn(
              'bg-orange-50 border border-neutral-0/5 rounded-md aspect-square opacity-50 transition-opacity overflow-hidden',
              carouselItem.id === visibleCarouselItem.id && 'opacity-100',
            )}
            onClick={() => handleClick(carouselItem)}
          >
            <Image src={carouselItem.image} width={73} height={73} alt='Preview' />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
